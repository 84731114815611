<template>
  <div class="select">
    <el-select ref="select" v-model="value" :popper-append-to-body="false" placeholder="请选择" @change="changeHanlder">
      <el-option v-for="option in options" :key="option.id" :label="option.label" :value="option.id">
        <div v-if="rightIcon">
          <span style="float: left">{{ option.label }}</span>
          <i class="iconfont icon-dcicon_tick" style="float: right" v-show="option.id === value"></i>
        </div>
      </el-option>
      <slot name="footer"></slot>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    options: Array,
    rightIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: ''
    };
  },
  methods: {
    changeHanlder(value) {
      this.$emit('update:value', value);
      this.$emit('change', value);
    },
    blur() {
      this.$refs.select.blur();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/common/style/var';
@import '../style/index.scss';

/deep/ .el-select-dropdown {
  display: flex;
  flex-direction: column;
  background: #060606;
  border-color: #222;
  border-radius: 5px;
  .el-scrollbar {
    order: 1;
    display: block !important;
    border-radius: 5px;
    .el-select-dropdown__list {
      background: $secondary-color;
      .el-select-dropdown__item {
        color: #bdbdbd;
        &:hover {
          color: $--color-primary;
          background: #121212 !important;
        }
      }
    }
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background: none;
  }
  .el-select-dropdown__item.selected {
    color: $--color-primary !important;
    background: #121212 !important;
  }
  .el-select-dropdown__empty {
    padding-top: 70px;
  }
}
/deep/ .el-popper[x-placement^='bottom'] .popper__arrow::after {
  top: 0;
  border-bottom-color: #060606;
}
</style>
