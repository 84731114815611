<template>
  <div class="playSettings">
    <MapDialog title="设置" width="500px" @comfirm="comfirm" @cancel="cancel">
      <el-form ref="form" :model="form" :rules="rules" label-width="110px">
        <el-form-item label="播放设置">
          <el-radio v-model="playType" label="loop">循环播放</el-radio>
          <el-radio v-model="playType" label="fixed">固定播放</el-radio>
        </el-form-item>
        <el-form-item label="每张地图播放" prop="timeInterval" v-show="LoopPlay">
          <BaseRow justify="space-between">
            <BaseCol :num="16">
              <el-input v-model.number="form.timeInterval" placeholder="请输入内容"></el-input>
            </BaseCol>
            <BaseCol>
              <MapSelect ref="MapSelect" :value.sync="timeType" :options="options" />
            </BaseCol>
          </BaseRow>
        </el-form-item>
      </el-form>
    </MapDialog>
  </div>
</template>

<script>
import BaseRow from '@/components/row/base-row';
import BaseCol from '@/components/col/base-col';
import MapDialog from '../components/dialog';
import MapSelect from '../components/select';
export default {
  data() {
    const checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('播放间隔不能为空'));
      }
      if (!Number.isInteger(value)) {
        callback(new Error('请输入整数值'));
      }
      callback();
    };
    return {
      playType: 'loop',
      options: [
        {
          id: 'second',
          label: '秒'
        },
        {
          id: 'minute',
          label: '分钟'
        },
        {
          id: 'hour',
          label: '小时'
        }
      ],
      timeType: '',
      form: {
        timeInterval: 60
      },
      rules: {
        timeInterval: [{ required: true, trigger: 'blur', validator: checkAge }]
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.timeType = 'second';
      this.$refs.MapSelect.value = 'second';
    });
  },
  methods: {
    comfirm() {
      const { id, mapId } = JSON.parse(localStorage.getItem('FactoryMap'));
      let href = `${location.origin}/map/play`;
      const isFixedPlay = this.playType === 'fixed';

      if (isFixedPlay) {
        href += `?mapId=${mapId}`;
        window.open(href);
      } else {
        const { timeInterval } = this.form;
        let delay;
        switch (this.timeType) {
          case 'second':
            delay = timeInterval;
            break;
          case 'minute':
            delay = timeInterval * 60;
            break;
          case 'hour':
            delay = timeInterval * 60 * 60;
            break;
        }

        this.$refs.form.validate(valid => {
          if (valid) {
            href += `?factoryId=${id}&delay=${delay}`;
            window.open(href);
          }
        });
      }
      this.$router.back();
    },
    cancel() {
      this.$router.back();
    }
  },
  computed: {
    LoopPlay() {
      return this.playType === 'loop';
    }
  },
  components: {
    BaseRow,
    BaseCol,
    MapDialog,
    MapSelect
  }
};
</script>
